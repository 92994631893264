import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { infoRound, dropdown } from "../../assets/images";
import ContactUs from "../../components/contact-us";
import { EXAMPLE1, EXAMPLE2 } from "../../constants/BatteryCalc";

const BatteryCalculator = React.memo(() => {
  const totalKwhList = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65]
  const [totalKWh, setTotalKWh] = useState({ error: false, value: 40 })
  const [consumptionBlock, setConsumptionBlock] = useState(EXAMPLE1);

  const [estimatedConsumption120, setestimatedConsumption120] = useState('')
  const [estimatedCoverage120, setestimatedCoverage120] = useState('')
  const [exampleNum, setExampleNum] = useState(0)

  const isNotNumber = (value) => {
    if (value)
      return !(/^(([0-9]+(\.[0-9]+)?)|(\.[0-9]+))$/.test(value));
  }

  function fixDecimalValue(value) {
    if (value > 1 && !Number.isInteger(value) && isFinite(value)) {
      const days = Math.floor(value);
      const hours = Math.floor((value - days) * 24);
      return `${days > 1 ? days + ' Days' : days + ' Day'} ${hours} Hrs`
    }
    else if (value > 0 && value < 1) {
      return `${(value * 24).toFixed(2)} Hrs`
    }
    else if (Number.isInteger(value)) {
      return `${value > 1 ? value + ' Days' : value + ' Day'} `;
    }
    else if (!isFinite(value)) {
      return `0 Days`;
    }
    else {
      return `${value.toFixed(2)} Days`;
    }
  }

  const handlePowerConsumption120 = (index, type, value) => {
    let updatedData = [...consumptionBlock];
    const valueFormatted = type === 'name' ? value : (value && !isNaN(value) ? value : null);
    updatedData[index] = {
      ...updatedData[index],
      [type]: valueFormatted,
      errorMsg: type === 'power' && isNotNumber(value) ? 'Invalid format' : '',
      error: type === 'power' && isNotNumber(value),
    }
    setConsumptionBlock(updatedData)
  }

  const calculateEstimatedValues120 = () => {
    setExampleNum(0)
    setTotalKWh({ ...totalKWh, error: totalKWh.value ? false : true })
    const filtered = consumptionBlock.filter(element => element !== undefined && element !== null && element.power)
    const sumOfPowerValues = filtered.map((v) => parseFloat(v?.power)).reduce((acc, val) => acc + val, 0)
    setestimatedConsumption120(`${sumOfPowerValues || 0} kWh`)
    setestimatedCoverage120(`${fixDecimalValue(parseFloat(totalKWh.value) / sumOfPowerValues) || 0} `)
  }

  const handleTotalKWh = (value) => {
    setTotalKWh({ value: value, error: isNotNumber(value), errorMsg: isNotNumber(value) ? 'Invalid format' : null })
  }

  const setStateClear = () => {
    setTotalKWh({ error: false, value: 30 })
    setConsumptionBlock(Array(consumptionBlock.length).fill(
      {
        name: '',
        power: '',
        error: false,
        errorMsg: ""
      }
    ))
    setExampleNum(0)
    setestimatedConsumption120('')
    setestimatedCoverage120('')
  }

  useEffect(() => {
    // if (exampleNum > 0)
    calculateEstimatedValues120()
  }, [exampleNum])

  return (
    <>
      <div className="wrapper">
        <div className="main-content battery-main-content">
          <section className="calculator-start-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">Battery Education</Typography>
                <div className="battery-content">
                  <p>
                    Without a battery system solar that is interconnected with
                    the electrical grid can't keep producing power in an outage.
                    This is a very common misconception. Batteries are required
                    for power in an outage.
                  </p>
                  <p>
                    Depending on the utility company and how they treat the
                    exported energy into the grid a battery can provide a
                    financial benefit keeping the power instead of sending it to
                    the utility company. This is often referenced as creating
                    your own micro grid.
                  </p>
                  <p>
                    Typically the two different streams of thought are backing
                    up essential loads power or trying to back up the entire
                    home. With an essential loads system the installer will do a
                    sub panel off the main electrical panel. In an outage the
                    automatic transfer switch moves the power from the main to
                    the sub panel. The sub panel will typically only contain 120
                    V power loads. Common loads would be fridge, freezer,
                    lighting, and wifi. Commonly not doing any 240 V loads.
                    Specifically leaving off of Ac units and pool pumps.
                  </p>
                  <p>
                    Whole home battery back up still requires being conservative
                    with your power in an outage most likely (if cost is an
                    issue). Load controllers are often very important to achieve
                    whole home battery back up. 240 V loads are able to be
                    backed up. The systems will work very quickly in an outage.
                    Often times in milliseconds they switch power from grid to
                    all solar and batteries. If the sun is reaching the panels
                    most systems will still allow the solar panels to work in an
                    outage. A lot of utility companies, federal, state, and
                    local governments are offering incentives to do batteries
                    right now to help the electrical grids be more safe.
                  </p>
                  <p>This battery calculator below is for whole home battery back up.</p>
                </div>
              </div>
            </Container>
          </section>

          {/* Add Battery Block */}
          <section className="add-battery-section">
            <Container maxWidth="xl">
              <div className="battery-box">
                <div className="battery-info">
                  <div className="bi-inner">
                    <Typography variant="h2" className="h2">
                      $ 7.5k
                    </Typography>
                    <span>5 kWh</span>
                  </div>
                  <div className="bi-inner">
                    <Typography variant="h2" className="h2">
                      $ 10k
                    </Typography>
                    <span>10 kWh</span>
                  </div>
                  <div className="bi-inner">
                    <Typography variant="h2" className="h2">
                      $ 12.5k
                    </Typography>
                    <span>15 kWh</span>
                  </div>
                  <div className="bi-inner">
                    <Typography variant="h2" className="h2">
                      $ 15k
                    </Typography>
                    <span>20 kWh</span>
                  </div>
                  <div className="bi-inner">
                    <Typography variant="h2" className="h2">
                      $ 17.5k
                    </Typography>
                    <span>25 kWh</span>
                  </div>
                  <div className="bi-inner">
                    <Typography variant="h2" className="h2">
                      $ 20k
                    </Typography>
                    <span>30 kWh</span>
                  </div>
                </div>
              </div>
            </Container>
          </section>

          {/* Battery Calculate Block */}
          <section className="battery-calculate">
            <Container maxWidth="xl">
              <Typography variant="h3" className="h3">
                Battery calculator
              </Typography>
              <div className="battery-card">
                <div className="battery-card-value">
                  <FormControl fullWidth>
                    <InputLabel>Total amount of battery for system</InputLabel>
                    <Select
                      label="Total amount of battery for system"
                      value={totalKWh.value}
                      onChange={(e) => handleTotalKWh(e.target.value)}
                      id="home-select"
                      IconComponent={() => (
                        <img src={dropdown} alt="arrow" />
                      )}
                    >
                      {totalKwhList.map((kWh) => {
                        return (
                          <MenuItem key={kWh} value={kWh}>{kWh} kWh</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="battery-power-card">
                  <Typography variant="h6">
                    Projected Daily kWh Consumption
                  </Typography>
                  <div className="main-row">
                    {consumptionBlock && consumptionBlock.map((obj, index) => {
                      return (
                        <div className="row-left" key={index}>
                          <div className="inner-row">
                            <div className="row-field field-big">
                              <TextField
                                fullWidth
                                placeholder="Name"
                                value={obj?.name}
                                onChange={(e) => handlePowerConsumption120(index, 'name', e.target.value)}
                              />
                            </div>
                            <div className="row-field field-small">
                              <TextField
                                fullWidth
                                placeholder="kWh"
                                value={obj?.power}
                                onChange={(e) => handlePowerConsumption120(index, 'power', e.target.value)}
                                error={consumptionBlock[index]?.error}
                                helperText={consumptionBlock[index]?.errorMsg ?? ''}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="battery-action">
                    <Button variant="contained" color="primary" onClick={() => setConsumptionBlock([...consumptionBlock, { name: '', breaker: '', power: '' }])}>
                      Add load
                    </Button>
                  </div>
                </div>
                <div className="button-combo-group">
                  <div className="combo-left">
                    <Button variant="contained" color="primary" onClick={() => calculateEstimatedValues120()}>
                      calculate
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setStateClear()}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className="combo-right">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setTotalKWh({ error: false, value: 30 })
                        setConsumptionBlock(EXAMPLE1)
                        setExampleNum(1)
                      }}
                    >
                      Example 1
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setTotalKWh({ error: false, value: 30 })
                        setConsumptionBlock(EXAMPLE2)
                        setExampleNum(2)
                      }}
                    >
                      Example 2
                    </Button>
                  </div>
                </div>
                <div className="estimate-box" style={{ marginBottom: "25px" }}>
                  <div className="estimate-left">
                    <p className="estimate-text">
                      Estimated kWh consumption per day
                      <Tooltip
                        title="Here we are estimating the amount of power that the solar system is going to work on supplying to the home or business."
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <img src={infoRound} alt="info" />
                        </IconButton>
                      </Tooltip>
                    </p>
                    <div className="estimate-value">
                      <TextField fullWidth value={estimatedConsumption120} InputProps={{ readOnly: true }} />
                    </div>
                  </div>
                  <div className="estimate-right">
                    <p className="estimate-text">
                      Estimated Coverage by battery
                      <Tooltip
                        title="How many days the home or business would be covered for with this battery system."
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <img src={infoRound} alt="info" />
                        </IconButton>
                      </Tooltip>
                    </p>
                    <div className="estimate-value">
                      <TextField fullWidth value={estimatedCoverage120} InputProps={{ readOnly: true }} />
                    </div>
                  </div>
                </div>
                <p style={{ fontSize: '1.4rem', color: '#919DA8', textAlign: 'center', lineHeight: '1.8rem' }}>Calculations not factoring additional power from solar panels or the limitation of discharge capacity of batteries.</p>
              </div>
            </Container>
          </section>
          <ContactUs />
        </div>
      </div>
    </>
  );
});

export default BatteryCalculator;
