import React from 'react'
import { Container, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { infoWhite } from "../../../assets/images";

export default function AddCost() {
  return (
    <>
      <section className="add-cost-section">
        <Container maxWidth="xl">
          <div className="cost-bg">
            <Typography variant="h3" className="h3">
              Potential additional cost
            </Typography>
            <div className="additional-box">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="additional-box-detail">
                    <Typography variant="h2" className="h2">
                      $ 0.5/W
                    </Typography>
                    <span>
                      Groundmount{" "}
                      <Tooltip
                        title="Groundmounts can have a lot of variables in their costs. Digging holes, concrete, and trenching can get expensive. Also sometimes not possible because of building jurisdiction restrictions."
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <img src={infoWhite} alt="info" />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="additional-box-detail">
                    <Typography variant="h2" className="h2">
                      $ 2-5K
                    </Typography>
                    <span>
                      Upgrade electrical work{" "}
                      <Tooltip
                        title="Solar is most commonly connected to the electrical grid. In these circumstances the electrical must be up to current code.   With a lot of electricity coming from the solar panels this sometimes requires upgrades to outdated or unsafe electrical at homes and businesses."
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <IconButton>
                          <img src={infoWhite} alt="info" />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
