import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";

const Financing = () => {
  return (
    <>
      <div className="wrapper">
        <div className="main-content finance-main-content">
          <section className="calculator-start-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">Financing</Typography>
                <div className="finance-content">
                  <p>
                    Local Solar installers have access to provide third party
                    zero down financing that allows for monthly payments.
                  </p>
                  <p>
                    Zero down financing has really propelled the solar industry.
                    These options have allowed much more people to financially
                    afford solar. Very important to weigh getting your own money
                    or your own financing vs using a third party option. If you
                    using your own financing we suggest looking into security
                    backed loans first. Home equity line or loan. Maybe a loan
                    via a 401k if it is available as an option.
                  </p>
                  <p>
                    If using third party financing always important to really
                    know what the loan is that is being offered. Using a local
                    installer typically helps avoid a lot of the negatives of
                    being taking advantage of with a zero down loan option.
                    Loans can range from 5 years to 30 years.
                  </p>
                  <p>
                    Typically the 30 year loans will have the lowest monthly
                    payments. Often times less expensive than would be the
                    electric bill.
                  </p>
                </div>
              </div>

              <div className="contact-us-section">
                <div className="contact-block">
                  <div className="contact-bg-left"></div>
                  <div className="contact-bg-right"></div>
                  <Typography variant="h2">Contact us</Typography>
                  <p>
                    If you would like communication please provide your
                    information We respect your privacy.
                  </p>
                  <p>
                    We will connect you with a local installer in your area that
                    can provide solar at $2.50 per watt or cheaper for you. We
                    always suggest vetting the local solar installer before
                    making a decision. We just hope to point you in the right
                    direction.
                  </p>
                  <div className="button-block">
                    <Link to="/contact-us" className="w-100">
                      <Button variant="contained" color="primary">
                        Contact Us
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
};

export default Financing;
