import React from "react";
import { Container, Typography, Button, Grid } from "@mui/material";
import { check, solarGroup, solarSingle } from "../../assets/images";
import ContactUs from "../../components/contact-us";

const Materials = () => {
  const pdfURL = '/downloads/MSolarSheet.pdf';

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = pdfURL;
    link.download = 'MSolarSheet.pdf';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="wrapper">
        <div className="main-content material">
          <section className="calculator-start-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">Materials</Typography>
              </div>
            </Container>
            <div className="materials-content-reverse">
              <Container maxWidth="xl">
                <div className="materials-content-block">
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={6} className="grid-left">
                      <em className="solar-panel-box">
                        <img src={solarSingle} alt="solar" />
                      </em>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="grid-right">
                      <div className="solar-detail">
                        <div className="detail-header">
                          <Typography variant="h4" className="h4">
                            M Solar 400 watt panel
                          </Typography>
                          {/* <Typography variant="h6">
                            SE-182*91-400M-108N
                          </Typography> */}
                        </div>
                        <div className="detail-body">
                          <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div className="solar-info">
                                <em className="solar-check">
                                  <img src={check} alt="check" />
                                </em>
                                <div className="solar-info-content">
                                  <p className="info-name">Efficiency</p>
                                  <Typography variant="h5" className="h5">
                                    20.48%
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div className="solar-info">
                                <em className="solar-check">
                                  <img src={check} alt="check" />
                                </em>
                                <div className="solar-info-content">
                                  <p className="info-name">Warranty</p>
                                  <Typography variant="h5" className="h5">
                                    25 Years
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div className="solar-info">
                                <em className="solar-check">
                                  <img src={check} alt="check" />
                                </em>
                                <div className="solar-info-content">
                                  <p className="info-name">Degradation</p>
                                  <Typography variant="h5" className="h5">
                                    0.5% per year
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div className="solar-info">
                                <em className="solar-check">
                                  <img src={check} alt="check" />
                                </em>
                                <div className="solar-info-content">
                                  <p className="info-name">Cell type</p>
                                  <Typography variant="h5" className="h5">
                                    Mono
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div className="solar-info">
                                <em className="solar-check">
                                  <img src={check} alt="check" />
                                </em>
                                <div className="solar-info-content">
                                  <p className="info-name">Production after 25 years estimated</p>
                                  <Typography variant="h5" className="h5">
                                    84.3%
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="detail-footer">
                          <Button variant="contained" color="primary" onClick={downloadPDF}>
                            Download
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
            <ContactUs />
          </section>
        </div>
      </div>
    </>
  );
};

export default Materials;
