import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function ContactUs() {
  return (
    <section className="contact-us-section">
      <Container maxWidth="xl">
        <div className="contact-block">
          <div className="contact-bg-left"></div>
          <div className="contact-bg-right"></div>
          <Typography variant="h2">Contact us</Typography>
          <p>
            If you would like communication please provide your
            information We respect your privacy.
          </p>
          <p>
            We will connect you with a local installer in your area that
            can provide solar at $2.50 per watt or cheaper for you. We
            always suggest vetting the local solar installer before making
            a decision. We just hope to point you in the right direction.
          </p>
          <div className="button-block">
            <Link to="/contact-us" className='w-100'>
              <Button variant="contained" color="primary">
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}
