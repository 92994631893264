import React from "react";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { footerLogo } from "../../assets/images";

const Footer = React.memo(() => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  return (
    <>
      <footer className="footer">
        <Container maxWidth="xl">
          <div className="footer-top">
            <Link to="/" title="Logo" className="ft-left" onClick={() => scrollToTop()}>
              <img src={footerLogo} alt="logo" />
            </Link>
            <ul className="ft-right">
              <li>
                <Link to="/about-us" title="About Us" onClick={() => scrollToTop()}>
                  About us
                </Link>
              </li>
              <li>
                <Link to="/materials" title="Materials" onClick={() => scrollToTop()}>
                  Materials
                </Link>
              </li>
              <li>
                <Link
                  to="/battery-calculate"
                  title="Battery Calculator"
                  onClick={() => scrollToTop()}
                >
                  Battery calculator
                </Link>
              </li>
              <li>
                <Link to="/financing" title="Financing" onClick={() => scrollToTop()}>
                  Financing
                </Link>
              </li>
              <li>
                <Link to="/contact-us" title="Contact Us" onClick={() => scrollToTop()}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/faq" title="FAQ" onClick={() => scrollToTop()}>
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-bottom">
            <p className="fb-left">
              <Link>Localpower.io</Link> is a software for solar built for high
              quality local installers.
            </p>
            <p className="fb-right">2023 Localpower.io</p>
          </div>
        </Container>
      </footer>
    </>
  );
});

export default Footer;
