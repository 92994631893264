import React from "react";
import { Container, Typography } from "@mui/material";
import ContactUs from "../../components/contact-us";

const About = () => {
  return (
    <>
      <div className="wrapper">
        <div className="main-content about-main-content">
          <section className="calculator-start-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">About Localpower.io</Typography>
                <div className="about-content">
                  <p>
                    This is a software created for local companies that want to
                    provide fair pricing. We created this calculator to create a
                    lot of transparency for anybody who wanted to get a fair
                    pricing.
                  </p>
                  <p>
                    We have searched out and are currently searching out the
                    best local companies to work with us in the effort to be
                    able to provide people all over the USA a good and fair
                    local solar option.
                  </p>
                  <p>
                    Feel free to use the calculator without any worry we are
                    going your info to track you or contact you. We will only
                    contact you if you click on contact us and input your
                    personal information.
                  </p>
                  <p>
                    Thank you so much for the consideration and any way you go
                    with solar we hope you get a fair and honest deal!
                  </p>
                </div>
              </div>
            </Container>
          </section>
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default About;
