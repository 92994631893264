import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// UI Routing List
import Home from "./pages/home";
import About from "./pages/about-us";
import Materials from "./pages/materials";
import BatteryCalculator from "./pages/battery-calculate";
import Financing from "./pages/financing";
import ContactUs from "./pages/contact-us";
import Main from "./pages/main";
import Faq from "./pages/faq";

export const RouteList = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/materials" element={<Materials />} />
          <Route path="/battery-calculate" element={<BatteryCalculator />} />
          <Route path="/financing" element={<Financing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
        </Route>
      </Routes>
    </BrowserRouter >
  );
};
