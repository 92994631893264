import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { accordionOpenIc, accordionClose } from "../../assets/images";
import ContactUs from "../../components/contact-us";

const Faq = React.memo(() => {
  const [accordionOpen, setAccordionOpen] = React.useState("panel1");
  const accordionChange = (panel) => (event, newExpanded) => {
    setAccordionOpen(newExpanded ? panel : false);
  };
  return (
    <>
      <div className="wrapper">
        <div className="main-content faq-main-content">
          <section className="calculator-start-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">FAQ</Typography>
              </div>
              <div className="faq-block">
                <Accordion
                  expanded={accordionOpen === "panel1"}
                  onChange={accordionChange("panel1")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      How does solar work?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        Sunlight hits the solar panels and converts from sunlight to DC electrical power.   The vast majority of the time the power will then need to be converted to AC power through an inverter and then can be used in the home, sent back to the electrical grid, or it can be stored in a battery system.
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel2"}
                  onChange={accordionChange("panel2")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      What is a kWh?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        1 Kilowatt of power over an hour of time.   This is how utility companies mainly measure power consumption and bill.
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel3"}
                  onChange={accordionChange("panel3")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      What is net metering?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        A system in which solar panels or other renewable energy generators are connected to a public-utility power grid and surplus power is transferred onto the grid, allowing customers to offset the cost of power drawn from the utility.   Amount of credit varies on Utility company.                       </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel4"}
                  onChange={accordionChange("panel4")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      What power can be used with solar panels?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        If connected to the grid all power usage will remain the same as normal just the electric bill will change in accordance with the net metering rules.   If off grid will depend on capacity power of the solar panels, inverters, and batteries.
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel5"}
                  onChange={accordionChange("panel5")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      In a power outage what happens to my power with solar?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        With no battery the power will be shut off in accordance with electrical grid rules.   With a battery system rules must be followed to keep grid safe but power would be available in power outages in accordance with capacity of solar, inverters, and batteries.
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel6"}
                  onChange={accordionChange("panel6")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>Is solar power safe?  </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        Yes.  The electricity produced by a solar power system is no different than the electricity you get from your electricity distribution company.   The only difference is the source.   Moreover, it is among the safest sources of electricity consumption because it is neither hazardous nor does it release any toxic or harmful fases during production.
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* -------Old faqs---------- */}

                {/* <Accordion
                  expanded={accordionOpen === "panel7"}
                  onChange={accordionChange("panel7")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      Nostrud sed sed voluptate esse voluptate occaecat quis eu
                      fugiat consequat ut dolor tempor mollit
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel8"}
                  onChange={accordionChange("panel8")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      How much electricity does a solar plant produce?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel9"}
                  onChange={accordionChange("panel9")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>Why should I go solar?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={accordionOpen === "panel10"}
                  onChange={accordionChange("panel10")}
                >
                  <AccordionSummary
                    className="accordion-custom-icon"
                    expandIcon={<><img src={accordionClose} alt="close" /> <img src={accordionOpenIc} alt="open" /> </>}
                  >
                    <Typography>
                      Do solar panels generate electricity even during monsoon
                      and winter?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="faq-content">
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                      <p>
                        Lorem ipsum sint in cillum eu culpa est ea commodo ut in
                        sunt ut ullamco et amet amet irure irure laborum do sed
                        ex cupidatat officia nisi magna deserunt in aliquip
                        exercitation adipisicing veniam dolore elit est aliquip
                        non occaecat cupidatat aliquip tempor in do nulla
                        proident aute excepteur in velit duis reprehenderit quis
                        nulla ut sint officia
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion> */}
              </div>
            </Container>
          </section>
          <ContactUs />
        </div>
      </div>
    </>
  );
});

export default Faq;
