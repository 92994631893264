import React, { useRef } from "react";
import { Container, Typography, Grid, TextField, Button } from "@mui/material";
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import SuccessDialog from "../../components/successDialog";
import { API } from "../../utils/Api-call";

const schema = yup.object({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  telephone: yup.string().trim().matches(/^[0-9]+$/, "Invalid format").max(10).required('Telephone number is required'),
  email: yup.string().trim().email('This email is not a valid format').required('E-mail is required'),
  address: yup.string().trim().required('Address is required'),
  city: yup.string().trim().required('City is required'),
  state: yup.string().trim().required('State is required'),
  zip: yup.string().trim().matches(/^[0-9]+$/, "Invalid format").required('Zip is required'),
  message: yup.string().trim().required('Message is required'),
  captcha: yup.string().trim().required('Captch is required'),
}).required();

const ContactUs = React.memo(() => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [notSubmitted, setNotSubmitted] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const captchaRef = useRef(null)

  const successDialogClose = () => {
    setDialogOpen(false);
  };

  const { handleSubmit, values, formState: { errors }, setError, register, setValue, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    handleSubmitForm({ ...data })
  };

  const handleSubmitForm = (data) => {
    setDisableSubmit(true)
    const payload = {
      query: `mutation CrmContactUs($input: crmContactUs!) {
        crmContactUs(input: $input)
      }
      `,
      variables: {
        input: {
          ...data
        }
      }
    }
    API().post("https://n5rckm54pfb2hkcsgdz57ecjim.appsync-api.us-east-1.amazonaws.com/graphql", payload).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setNotSubmitted(false)
        reset()
        setDialogOpen(true);
      } else {
        setNotSubmitted(true)
      }
    }).catch((err) => {
      console.log(err)
      setNotSubmitted(true)
    }).finally(() => {
      setDisableSubmit(false)
      captchaRef.current.reset()
      setValue('captcha', null)
    })
  }

  return (
    <>
      <div className="wrapper">
        <div className="main-content">
          <section
            className="calculator-start-section contact-section"
          >
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">Contact Us</Typography>
                <>
                  <p className="cs-detail">
                    If you would like communication please provide your
                    information We respect your privacy.
                  </p>
                  <p className="cs-detail secondary-para">
                    We will connect you with a local installer in your area that
                    can provide solar at $2.50 per watt or cheaper for you. We
                    always suggest vetting the local solar installer before making
                    a decision. We just hope to point you in the right direction.
                  </p>
                </>
              </div>
            </Container>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="contact-form">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="First Name"
                      id="firstName"
                      name="firstName"
                      {...register('firstName')}
                      error={!!errors.firstName}
                      helperText={errors.firstName ? errors.firstName.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Last Name"
                      id="lastName"
                      name="lastName"
                      {...register('lastName')}
                      error={!!errors.lastName}
                      helperText={errors.lastName ? errors.lastName.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Telephone number"
                      id="telephone"
                      name="telephone"
                      {...register('telephone')}
                      error={!!errors.telephone}
                      helperText={errors.telephone ? errors.telephone.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="E-mail"
                      id="email"
                      name="email"
                      {...register('email')}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Address"
                      id="address"
                      name="address"
                      {...register('address')}
                      error={!!errors.address}
                      helperText={errors.address ? errors.address.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="City"
                      id="city"
                      name="city"
                      {...register('city')}
                      error={!!errors.city}
                      helperText={errors.city ? errors.city.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="State"
                      id="state"
                      name="state"
                      {...register('state')}
                      error={!!errors.state}
                      helperText={errors.state ? errors.state.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Zip"
                      id="zip"
                      name="zip"
                      {...register('zip')}
                      error={!!errors.zip}
                      helperText={errors.zip ? errors.zip.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      id="message"
                      name="message"
                      {...register('message')}
                      error={!!errors.message}
                      helperText={errors.message ? errors.message.message : ''}
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <ReCAPTCHA
                      {...register('captcha')}
                      sitekey="6LfvkG0mAAAAAMhMemuY-g-dhoplG6i4yWz9Q155"
                      name="captcha"
                      onErrored={(e) => setError('captcha', e)}
                      onExpired={(e) => setError('captcha', e)}
                      onChange={(e) => setValue('captcha', e)}
                      ref={captchaRef}
                    />
                    {errors.captcha && errors.captcha.message && <span style={{ color: '#da402f' }}>{errors.captcha.message}</span>}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="grid-mobile-hide"
                  ></Grid>
                  <div className="contact-action">
                    <Button variant="contained" color="primary" type="submit" disabled={disableSubmit}>
                      Submit
                    </Button>
                    {notSubmitted && <span style={{ color: '#da402f', marginTop: "10px" }}>Network Error</span>}
                  </div>
                </Grid>
              </div>
            </form>
          </section>
        </div>
      </div>
      <SuccessDialog open={dialogOpen} onClose={successDialogClose} />
    </>
  );
});

export default ContactUs;
