import React, { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import {
  infoRound,
  calendar,
} from "../../assets/images";
import ContactUs from "../../components/contact-us";
import SolarNumber from "./solarNumber";
import AddBattery from "./addBattery";
import AddCost from "./addCost";
import {
  MONTH_FIELDS,
  FIELDCONSUMPTIONPRICEPERKWH,
  FIELDCONSUMPTIONPRICEGRIDPERKWH,
  FIELDCONSUMPTIONPRICEBASEFEE,
  FIELDCONSUMPTIONSELFPRODUCEDPOWER,
  FIELDCONSUMPTIONANNUALUSAGE,
  FIELDCONSUMPTIONJANUARYUSAGE,
  FIELDCONSUMPTIONFEBRUARYUSAGE,
  FIELDCONSUMPTIONMARCHUSAGE,
  FIELDCONSUMPTIONAPRILUSAGE,
  FIELDCONSUMPTIONMAYUSAGE,
  FIELDCONSUMPTIONJUNEUSAGE,
  FIELDCONSUMPTIONJULYUSAGE,
  FIELDCONSUMPTIONAUGUSTUSAGE,
  FIELDCONSUMPTIONSEPTEMBERUSAGE,
  FIELDCONSUMPTIONOCTOBERUSAGE,
  FIELDCONSUMPTIONNOVEMBERUSAGE,
  FIELDCONSUMPTIONDECEMBERUSAGE,
  FIELDCONSUMPTIONINCREASEINKWH,
  FIELDCONSUMPTIONADDINCENTIVE,
  DEFAULT,
  EXAMPLE1,
  EXAMPLE2,
  EXAMPLE3,
} from "../../constants/SolarCalc";

const Home = React.memo(() => {
  const [solarCalculationData, setSolarCalculationData] = React.useState({
    totalPanels: '',
    totalSavingFirstYear: '',
    netSolarCost: '',
    returnOnSaving: '',
    returnOnSavingYears: '',
    returnOnSavingYearsUtility: '',
    totalProduction25Years: '',
    costToProducePower25Years: '',
    totalProductionIncludeDegradation: '',
    estimatedPriceToProducePower25years: ''
  });

  const [
    {
      fieldConsumptionPricePerKWH,
      fieldConsumptionPriceGridPerKWH,
      fieldConsumptionPriceBaseFee,
      fieldConsumptionSelfProducedPower,
      fieldConsumptionIncreaseInKwh,
      fieldConsumptionAdditionalIncentive,
      fieldConsumptionAnnualUsage,
      fieldConsumptionJanuaryUsage,
      fieldConsumptionFebruaryUsage,
      fieldConsumptionMarchUsage,
      fieldConsumptionAprilUsage,
      fieldConsumptionMayUsage,
      fieldConsumptionJuneUsage,
      fieldConsumptionJulyUsage,
      fieldConsumptionAugustUsage,
      fieldConsumptionSeptemberUsage,
      fieldConsumptionOctoberUsage,
      fieldConsumptionNovemberUsage,
      fieldConsumptionDecemberUsage,
      exampleNum
    },
    setState
  ] = React.useState({
    fieldConsumptionPricePerKWH: FIELDCONSUMPTIONPRICEPERKWH,
    fieldConsumptionPriceGridPerKWH: FIELDCONSUMPTIONPRICEGRIDPERKWH,
    fieldConsumptionPriceBaseFee: FIELDCONSUMPTIONPRICEBASEFEE,
    fieldConsumptionSelfProducedPower: FIELDCONSUMPTIONSELFPRODUCEDPOWER,
    fieldConsumptionIncreaseInKwh: FIELDCONSUMPTIONINCREASEINKWH,
    fieldConsumptionAdditionalIncentive: FIELDCONSUMPTIONADDINCENTIVE,
    fieldConsumptionAnnualUsage: FIELDCONSUMPTIONANNUALUSAGE,
    fieldConsumptionJanuaryUsage: FIELDCONSUMPTIONJANUARYUSAGE,
    fieldConsumptionFebruaryUsage: FIELDCONSUMPTIONFEBRUARYUSAGE,
    fieldConsumptionMarchUsage: FIELDCONSUMPTIONMARCHUSAGE,
    fieldConsumptionAprilUsage: FIELDCONSUMPTIONAPRILUSAGE,
    fieldConsumptionMayUsage: FIELDCONSUMPTIONMAYUSAGE,
    fieldConsumptionJuneUsage: FIELDCONSUMPTIONJUNEUSAGE,
    fieldConsumptionJulyUsage: FIELDCONSUMPTIONJULYUSAGE,
    fieldConsumptionAugustUsage: FIELDCONSUMPTIONAUGUSTUSAGE,
    fieldConsumptionSeptemberUsage: FIELDCONSUMPTIONSEPTEMBERUSAGE,
    fieldConsumptionOctoberUsage: FIELDCONSUMPTIONOCTOBERUSAGE,
    fieldConsumptionNovemberUsage: FIELDCONSUMPTIONNOVEMBERUSAGE,
    fieldConsumptionDecemberUsage: FIELDCONSUMPTIONDECEMBERUSAGE,
    exampleNum: 0
  })
  const solarNumberRef = useRef(null)
  const isNotNumber = (value) => {
    if (value)
      return !(/^(([0-9]+(\.[0-9]+)?)|(\.[0-9]+))$/.test(value));
  }
  const checkPercLimit = (value, key) => {
    if (key === 'fieldConsumptionSelfProducedPower') {
      return value > 100
    } else { return false }
  }

  const handleState = (event, key) => {
    const value = event.target.value;
    setState((previous) => {
      return {
        ...previous,
        [key]: {
          ...previous.key,
          value: value,
          error: isNotNumber(value) || checkPercLimit(value, key) ? true : false,
          errorMsg: isNotNumber(value) ? 'Invalid format' : checkPercLimit(value, key) ? 'Invalid value' : null,
        }
      }
    })
  }

  const handleError = (key) => {
    setState((previous) => {
      return {
        ...previous,
        [key]: {
          ...previous.key,
          error: true
        }
      }
    })
  }

  const handleAnnualUsageChange = (event) => {
    const value = event.target.value;

    setState((prevState) => ({
      ...prevState,
      fieldConsumptionAnnualUsage: {
        ...prevState.fieldConsumptionAnnualUsage,
        value: value,
        error: isNotNumber(value) ? true : false,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null
      },
      fieldConsumptionJanuaryUsage: {
        ...prevState.fieldConsumptionJanuaryUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionFebruaryUsage: {
        ...prevState.fieldConsumptionFebruaryUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionMarchUsage: {
        ...prevState.fieldConsumptionMarchUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionAprilUsage: {
        ...prevState.fieldConsumptionAprilUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionMayUsage: {
        ...prevState.fieldConsumptionMayUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionJuneUsage: {
        ...prevState.fieldConsumptionJuneUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionJulyUsage: {
        ...prevState.fieldConsumptionJulyUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionAugustUsage: {
        ...prevState.fieldConsumptionAugustUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionSeptemberUsage: {
        ...prevState.fieldConsumptionSeptemberUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionOctoberUsage: {
        ...prevState.fieldConsumptionOctoberUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionNovemberUsage: {
        ...prevState.fieldConsumptionNovemberUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
      fieldConsumptionDecemberUsage: {
        ...prevState.fieldConsumptionDecemberUsage,
        value: !isNotNumber(value) ? (value / 12).toFixed(2) : '0.00',
        error: false,
        errorMsg: null
      },
    }));
  };

  const handleMonthlyUsageChange = (event, field) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        value: value,
      },
      fieldConsumptionAnnualUsage: {
        ...prevState.fieldConsumptionAnnualUsage,
        value: calculateTotalUsage(prevState, field, value),
      },
      fieldConsumptionJanuaryUsage: {
        ...prevState.fieldConsumptionJanuaryUsage,
        value: field === 'fieldConsumptionJanuaryUsage' ? value : prevState.fieldConsumptionJanuaryUsage.value,
        error: field === 'fieldConsumptionJanuaryUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionJanuaryUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionFebruaryUsage: {
        ...prevState.fieldConsumptionFebruaryUsage,
        value: field === 'fieldConsumptionFebruaryUsage' ? value : prevState.fieldConsumptionFebruaryUsage.value,
        error: field === 'fieldConsumptionFebruaryUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionFebruaryUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionMarchUsage: {
        ...prevState.fieldConsumptionMarchUsage,
        value: field === 'fieldConsumptionMarchUsage' ? value : prevState.fieldConsumptionMarchUsage.value,
        error: field === 'fieldConsumptionMarchUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionMarchUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionAprilUsage: {
        ...prevState.fieldConsumptionAprilUsage,
        value: field === 'fieldConsumptionAprilUsage' ? value : prevState.fieldConsumptionAprilUsage.value,
        error: field === 'fieldConsumptionAprilUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionAprilUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionMayUsage: {
        ...prevState.fieldConsumptionMayUsage,
        value: field === 'fieldConsumptionMayUsage' ? value : prevState.fieldConsumptionMayUsage.value,
        error: field === 'fieldConsumptionMayUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionMayUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionJuneUsage: {
        ...prevState.fieldConsumptionJuneUsage,
        value: field === 'fieldConsumptionJuneUsage' ? value : prevState.fieldConsumptionJuneUsage.value,
        error: field === 'fieldConsumptionJuneUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionJuneUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionJulyUsage: {
        ...prevState.fieldConsumptionJulyUsage,
        value: field === 'fieldConsumptionJulyUsage' ? value : prevState.fieldConsumptionJulyUsage.value,
        error: field === 'fieldConsumptionJulyUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionJulyUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionAugustUsage: {
        ...prevState.fieldConsumptionAugustUsage,
        value: field === 'fieldConsumptionAugustUsage' ? value : prevState.fieldConsumptionAugustUsage.value,
        error: field === 'fieldConsumptionAugustUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionAugustUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionSeptemberUsage: {
        ...prevState.fieldConsumptionSeptemberUsage,
        value: field === 'fieldConsumptionSeptemberUsage' ? value : prevState.fieldConsumptionSeptemberUsage.value,
        error: field === 'fieldConsumptionSeptemberUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionSeptemberUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionOctoberUsage: {
        ...prevState.fieldConsumptionOctoberUsage,
        value: field === 'fieldConsumptionOctoberUsage' ? value : prevState.fieldConsumptionOctoberUsage.value,
        error: field === 'fieldConsumptionOctoberUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionOctoberUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionNovemberUsage: {
        ...prevState.fieldConsumptionNovemberUsage,
        value: field === 'fieldConsumptionNovemberUsage' ? value : prevState.fieldConsumptionNovemberUsage.value,
        error: field === 'fieldConsumptionNovemberUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionNovemberUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
      fieldConsumptionDecemberUsage: {
        ...prevState.fieldConsumptionDecemberUsage,
        value: field === 'fieldConsumptionDecemberUsage' ? value : prevState.fieldConsumptionDecemberUsage.value,
        error: field === 'fieldConsumptionDecemberUsage' ? isNotNumber(value) ? true : false : prevState.fieldConsumptionDecemberUsage.error,
        errorMsg: isNotNumber(value) ? 'Invalid format' : null,
      },
    }));
  };

  const calculateTotalUsage = (prevState, field, value) => {
    const newFieldValues = MONTH_FIELDS.map((key) => {
      if (key === field) {
        return parseFloat(value);
      }
      return parseFloat(prevState[key].value);
    });

    const totalUsage = newFieldValues.filter(Boolean).reduce(
      (sum, usage) => sum + Number(usage),
      0
    );

    return parseFloat(totalUsage);
  };

  function roundOffNumber(number) {
    if (number >= 1000) {
      const numZeros = Math.floor(Math.log10(number) - 3);
      const divisor = Math.pow(10, numZeros);
      const roundedNumber = Math.round(number / divisor) * divisor;

      return roundedNumber;
    } else {
      return number;
    }
  }

  const calculateProductionIncludingDegradation = (value) => {
    let production = 0
    let newVal = value
    for (let i = 0; i < 25; i++) {
      newVal = i === 0 ? newVal * 0.98 : newVal * 0.995
      production = production + newVal
    }
    return roundOffNumber(Math.ceil(production));
  }

  const calculateReturnWithUtilityRate = (netCost, saving) => {
    let year = 0
    let cost = netCost
    let savings = saving
    let newVal = cost - savings;
    if (saving > 0) {
      for (let i = newVal; i > 0; i = cost - savings) {
        newVal = (cost - savings).toFixed(2);
        cost = newVal;
        savings = (savings * 103 / 100).toFixed(2)
        year = year + 1
      }
      let months = (newVal / savings) ?? 0
      return year + months
    } else return 0
  }

  const calculateSolarNumbers = () => {
    const PER_PANEL_PRODUCTION = 400
    const PER_PANEL_COST = 600
    const PER_WATT_COST = 2.5
    const PERC_OF_NET_COST = 0.70

    const annualUsageFloatValue = parseFloat(fieldConsumptionAnnualUsage.value);
    const priceGridFloatValue = parseFloat(fieldConsumptionPriceGridPerKWH.value) / 100;
    const productionDirectFloatValue = parseFloat(fieldConsumptionSelfProducedPower.value);
    const priceBaseFloatValue = parseFloat(fieldConsumptionPriceBaseFee.value);
    const consumptionPriceFloatValue = parseFloat(fieldConsumptionPricePerKWH.value) / 100;
    const totalPanels = Math.ceil(annualUsageFloatValue / PER_PANEL_COST)
    const totalCostPerYear = annualUsageFloatValue * consumptionPriceFloatValue;
    const totalProduction25Years = annualUsageFloatValue * 25
    const yearlyUsageBill = (totalCostPerYear * productionDirectFloatValue) / 100
    const utilityCredit = priceBaseFloatValue * 12
    const totalGridPays = (100 - productionDirectFloatValue) * annualUsageFloatValue * priceGridFloatValue / 100
    const totalSavingFirstYear = yearlyUsageBill - utilityCredit + totalGridPays;
    const netSolarCost = (totalPanels * PER_PANEL_PRODUCTION * PER_WATT_COST * PERC_OF_NET_COST) - parseFloat(fieldConsumptionAdditionalIncentive.value);
    const totalProductionIncludeDegradation = calculateProductionIncludingDegradation(annualUsageFloatValue)

    const solarNumbers = {
      totalPanels: totalPanels,
      totalSavingFirstYear: totalSavingFirstYear,
      netSolarCost: netSolarCost,
      returnOnSaving: (totalSavingFirstYear / netSolarCost) * 100,
      returnOnSavingYears: (netSolarCost / totalSavingFirstYear) ?? 0,
      returnOnSavingYearsUtility: calculateReturnWithUtilityRate(netSolarCost, totalSavingFirstYear),
      totalProduction25Years: totalProduction25Years,
      costToProducePower25Years: (netSolarCost / totalProduction25Years),
      totalProductionIncludeDegradation: totalProductionIncludeDegradation,
      estimatedPriceToProducePower25years: netSolarCost / totalProductionIncludeDegradation
    }

    Object.keys(solarNumbers).forEach((key) => {
      const value = solarNumbers[key];
      solarNumbers[key] = Number.isInteger(value) ? value.toFixed(0) : key === 'costToProducePower25Years' || key === 'estimatedPriceToProducePower25years' ? value.toFixed(4) : value.toFixed(2);
    });
    setSolarCalculationData(solarNumbers)
  }

  useEffect(() => {
    if (fieldConsumptionAnnualUsage.value && fieldConsumptionPricePerKWH.value) {
      calculateSolarNumbers()
    }
  }, [])


  const scrollToSolarNumber = () => {
    if (solarNumberRef.current) {
      const offsetTop = solarNumberRef.current.offsetTop
      window.scrollTo({
        top: offsetTop - 70,
        behavior: 'smooth'
      });
    }
  }

  const setExample1 = () => {
    setState({
      fieldConsumptionPricePerKWH: FIELDCONSUMPTIONPRICEPERKWH,
      fieldConsumptionPriceGridPerKWH: FIELDCONSUMPTIONPRICEGRIDPERKWH,
      fieldConsumptionPriceBaseFee: FIELDCONSUMPTIONPRICEBASEFEE,
      fieldConsumptionSelfProducedPower: FIELDCONSUMPTIONSELFPRODUCEDPOWER,
      fieldConsumptionIncreaseInKwh: FIELDCONSUMPTIONINCREASEINKWH,
      fieldConsumptionAdditionalIncentive: FIELDCONSUMPTIONADDINCENTIVE,
      fieldConsumptionAnnualUsage: FIELDCONSUMPTIONANNUALUSAGE,
      fieldConsumptionJanuaryUsage: FIELDCONSUMPTIONJANUARYUSAGE,
      fieldConsumptionFebruaryUsage: FIELDCONSUMPTIONFEBRUARYUSAGE,
      fieldConsumptionMarchUsage: FIELDCONSUMPTIONMARCHUSAGE,
      fieldConsumptionAprilUsage: FIELDCONSUMPTIONAPRILUSAGE,
      fieldConsumptionMayUsage: FIELDCONSUMPTIONMAYUSAGE,
      fieldConsumptionJuneUsage: FIELDCONSUMPTIONJUNEUSAGE,
      fieldConsumptionJulyUsage: FIELDCONSUMPTIONJULYUSAGE,
      fieldConsumptionAugustUsage: FIELDCONSUMPTIONAUGUSTUSAGE,
      fieldConsumptionSeptemberUsage: FIELDCONSUMPTIONSEPTEMBERUSAGE,
      fieldConsumptionOctoberUsage: FIELDCONSUMPTIONOCTOBERUSAGE,
      fieldConsumptionNovemberUsage: FIELDCONSUMPTIONNOVEMBERUSAGE,
      fieldConsumptionDecemberUsage: FIELDCONSUMPTIONDECEMBERUSAGE,
      exampleNum: 1
    })
    scrollToSolarNumber()
  }

  const setExample2 = () => {
    setState(oldState => ({
      fieldConsumptionPricePerKWH: EXAMPLE2.FIELDCONSUMPTIONPRICEPERKWH,
      fieldConsumptionPriceGridPerKWH: EXAMPLE2.FIELDCONSUMPTIONPRICEGRIDPERKWH,
      fieldConsumptionPriceBaseFee: EXAMPLE2.FIELDCONSUMPTIONPRICEBASEFEE,
      fieldConsumptionSelfProducedPower: EXAMPLE2.FIELDCONSUMPTIONSELFPRODUCEDPOWER,
      fieldConsumptionIncreaseInKwh: EXAMPLE2.FIELDCONSUMPTIONINCREASEINKWH,
      fieldConsumptionAdditionalIncentive: EXAMPLE2.FIELDCONSUMPTIONADDINCENTIVE,
      fieldConsumptionAnnualUsage: EXAMPLE2.FIELDCONSUMPTIONANNUALUSAGE,
      fieldConsumptionJanuaryUsage: EXAMPLE2.FIELDCONSUMPTIONJANUARYUSAGE,
      fieldConsumptionFebruaryUsage: EXAMPLE2.FIELDCONSUMPTIONFEBRUARYUSAGE,
      fieldConsumptionMarchUsage: EXAMPLE2.FIELDCONSUMPTIONMARCHUSAGE,
      fieldConsumptionAprilUsage: EXAMPLE2.FIELDCONSUMPTIONAPRILUSAGE,
      fieldConsumptionMayUsage: EXAMPLE2.FIELDCONSUMPTIONMAYUSAGE,
      fieldConsumptionJuneUsage: EXAMPLE2.FIELDCONSUMPTIONJUNEUSAGE,
      fieldConsumptionJulyUsage: EXAMPLE2.FIELDCONSUMPTIONJULYUSAGE,
      fieldConsumptionAugustUsage: EXAMPLE2.FIELDCONSUMPTIONAUGUSTUSAGE,
      fieldConsumptionSeptemberUsage: EXAMPLE2.FIELDCONSUMPTIONSEPTEMBERUSAGE,
      fieldConsumptionOctoberUsage: EXAMPLE2.FIELDCONSUMPTIONOCTOBERUSAGE,
      fieldConsumptionNovemberUsage: EXAMPLE2.FIELDCONSUMPTIONNOVEMBERUSAGE,
      fieldConsumptionDecemberUsage: EXAMPLE2.FIELDCONSUMPTIONDECEMBERUSAGE,
      exampleNum: 2
    }))
    scrollToSolarNumber()
  }

  const setExample3 = () => {
    setState(oldState => ({
      fieldConsumptionPricePerKWH: EXAMPLE3.FIELDCONSUMPTIONPRICEPERKWH,
      fieldConsumptionPriceGridPerKWH: EXAMPLE3.FIELDCONSUMPTIONPRICEGRIDPERKWH,
      fieldConsumptionPriceBaseFee: EXAMPLE3.FIELDCONSUMPTIONPRICEBASEFEE,
      fieldConsumptionSelfProducedPower: EXAMPLE3.FIELDCONSUMPTIONSELFPRODUCEDPOWER,
      fieldConsumptionIncreaseInKwh: EXAMPLE3.FIELDCONSUMPTIONINCREASEINKWH,
      fieldConsumptionAdditionalIncentive: EXAMPLE3.FIELDCONSUMPTIONADDINCENTIVE,
      fieldConsumptionAnnualUsage: EXAMPLE3.FIELDCONSUMPTIONANNUALUSAGE,
      fieldConsumptionJanuaryUsage: EXAMPLE3.FIELDCONSUMPTIONJANUARYUSAGE,
      fieldConsumptionFebruaryUsage: EXAMPLE3.FIELDCONSUMPTIONFEBRUARYUSAGE,
      fieldConsumptionMarchUsage: EXAMPLE3.FIELDCONSUMPTIONMARCHUSAGE,
      fieldConsumptionAprilUsage: EXAMPLE3.FIELDCONSUMPTIONAPRILUSAGE,
      fieldConsumptionMayUsage: EXAMPLE3.FIELDCONSUMPTIONMAYUSAGE,
      fieldConsumptionJuneUsage: EXAMPLE3.FIELDCONSUMPTIONJUNEUSAGE,
      fieldConsumptionJulyUsage: EXAMPLE3.FIELDCONSUMPTIONJULYUSAGE,
      fieldConsumptionAugustUsage: EXAMPLE3.FIELDCONSUMPTIONAUGUSTUSAGE,
      fieldConsumptionSeptemberUsage: EXAMPLE3.FIELDCONSUMPTIONSEPTEMBERUSAGE,
      fieldConsumptionOctoberUsage: EXAMPLE3.FIELDCONSUMPTIONOCTOBERUSAGE,
      fieldConsumptionNovemberUsage: EXAMPLE3.FIELDCONSUMPTIONNOVEMBERUSAGE,
      fieldConsumptionDecemberUsage: EXAMPLE3.FIELDCONSUMPTIONDECEMBERUSAGE,
      exampleNum: 3
    }))
    scrollToSolarNumber()
  }

  const setStateClear = () => {
    setState(oldState => ({
      fieldConsumptionPricePerKWH: DEFAULT,
      fieldConsumptionPriceGridPerKWH: DEFAULT,
      fieldConsumptionPriceBaseFee: DEFAULT,
      fieldConsumptionSelfProducedPower: DEFAULT,
      fieldConsumptionIncreaseInKwh: DEFAULT,
      fieldConsumptionAdditionalIncentive: DEFAULT,
      fieldConsumptionAnnualUsage: DEFAULT,
      fieldConsumptionJanuaryUsage: DEFAULT,
      fieldConsumptionFebruaryUsage: DEFAULT,
      fieldConsumptionMarchUsage: DEFAULT,
      fieldConsumptionAprilUsage: DEFAULT,
      fieldConsumptionMayUsage: DEFAULT,
      fieldConsumptionJuneUsage: DEFAULT,
      fieldConsumptionJulyUsage: DEFAULT,
      fieldConsumptionAugustUsage: DEFAULT,
      fieldConsumptionSeptemberUsage: DEFAULT,
      fieldConsumptionOctoberUsage: DEFAULT,
      fieldConsumptionNovemberUsage: DEFAULT,
      fieldConsumptionDecemberUsage: DEFAULT,
      exampleNum: 0
    }))
  }

  useEffect(() => {
    if (exampleNum !== 0) {
      calculateSolarNumbers()
    }
  }, [exampleNum])


  return (
    <>
      <div className="wrapper">
        <div className="main-content">
          <section className="calculator-start-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1" className="custom-width">
                  Solar Calculator
                </Typography>
                <p className="cs-detail">
                  We will not use any of your information unless you fill out
                  the form below the calculator. This is designed to give you
                  transparent pricing.
                </p>
              </div>
              <Card>
                <CardContent>
                  <Grid container spacing={3} className="grid-custom-space">
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="form-label">
                        Total cost per kWh in Cents
                        <Tooltip
                          title="Search for your electric bill- look for kWh cost to find this number."
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <img src={infoRound} alt="info" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <TextField
                        required
                        variant="outlined"
                        fullWidth
                        value={fieldConsumptionPricePerKWH.value}
                        onChange={(e) => handleState(e, 'fieldConsumptionPricePerKWH')}
                        error={fieldConsumptionPricePerKWH.error}
                        helperText={fieldConsumptionPricePerKWH.errorMsg ? fieldConsumptionPricePerKWH.errorMsg : fieldConsumptionPricePerKWH.error ? 'Required' : ''}
                        onBlur={() => !fieldConsumptionPricePerKWH.value && handleError('fieldConsumptionPricePerKWH')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="form-label">
                        Base Fee
                        <Tooltip
                          title="Base fee or grid fee. Created for base revenue for utility."
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <img src={infoRound} alt="info" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fieldConsumptionPriceBaseFee.value}
                        onChange={(e) => handleState(e, 'fieldConsumptionPriceBaseFee')}
                        error={fieldConsumptionPriceBaseFee.error}
                        helperText={fieldConsumptionPriceBaseFee.errorMsg ? fieldConsumptionPriceBaseFee.errorMsg : fieldConsumptionPriceBaseFee.error ? 'Required' : ''}
                        onBlur={() => !fieldConsumptionPriceBaseFee.value && handleError('fieldConsumptionPriceBaseFee')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="form-label">
                        Grid pays per kWH exported in Cents
                        <Tooltip
                          title="How does utility interact with electricity sent back to grid? Search on utility website or contact them."
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <img src={infoRound} alt="info" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fieldConsumptionPriceGridPerKWH.value}
                        onChange={(e) => handleState(e, 'fieldConsumptionPriceGridPerKWH')}
                        error={fieldConsumptionPriceGridPerKWH.error}
                        helperText={fieldConsumptionPriceGridPerKWH.errorMsg ? fieldConsumptionPriceGridPerKWH.errorMsg : fieldConsumptionPriceGridPerKWH.error ? 'Required' : ''}
                        onBlur={() => !fieldConsumptionPriceGridPerKWH.value && handleError('fieldConsumptionPriceGridPerKWH')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="form-label">
                        % of production self-used
                        <Tooltip
                          title="Power used directly in your home and not send back to the utility grid. The effect is your get full credit for the power because it is like you never used it to the utility company. This translates the highest savings."
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <img src={infoRound} alt="info" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fieldConsumptionSelfProducedPower.value}
                        onChange={(e) => handleState(e, 'fieldConsumptionSelfProducedPower')}
                        error={fieldConsumptionSelfProducedPower.error}
                        helperText={fieldConsumptionSelfProducedPower.errorMsg ? fieldConsumptionSelfProducedPower.errorMsg : fieldConsumptionSelfProducedPower.error ? 'Required' : ''}
                        onBlur={() => !fieldConsumptionSelfProducedPower.value && handleError('fieldConsumptionSelfProducedPower')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="form-label">
                        Increase in kWh cost (%)
                        <Tooltip
                          title="How much do you expect the rates per kWh to go up year over year for the next 25 years?  averaged out."
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <img src={infoRound} alt="info" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fieldConsumptionIncreaseInKwh.value}
                        onChange={(e) => handleState(e, 'fieldConsumptionIncreaseInKwh')}
                        error={fieldConsumptionIncreaseInKwh.error}
                        helperText={fieldConsumptionIncreaseInKwh.errorMsg ? fieldConsumptionIncreaseInKwh.errorMsg : fieldConsumptionIncreaseInKwh.error ? 'Required' : ''}
                        onBlur={() => !fieldConsumptionIncreaseInKwh.value && handleError('fieldConsumptionIncreaseInKwh')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="form-label">
                        Additional Incentives
                        <Tooltip
                          title="If the utility provides extra incentives for doing solar add it hear. This is an overall value towards going solar."
                          arrow
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <IconButton>
                            <img src={infoRound} alt="info" />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        value={fieldConsumptionAdditionalIncentive.value}
                        onChange={(e) => handleState(e, 'fieldConsumptionAdditionalIncentive')}
                        error={fieldConsumptionAdditionalIncentive.error}
                        helperText={fieldConsumptionAdditionalIncentive.errorMsg ? fieldConsumptionAdditionalIncentive.errorMsg : fieldConsumptionAdditionalIncentive.error ? 'Required' : ''}
                        onBlur={() => !fieldConsumptionAdditionalIncentive.value && handleError('fieldConsumptionAdditionalIncentive')}
                      />
                    </Grid>
                  </Grid>

                  {/* Calculation Annual Block */}
                  <div className="annual-box-outer">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">January</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionJanuaryUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionJanuaryUsage')}
                            error={fieldConsumptionJanuaryUsage.error}
                            helperText={fieldConsumptionJanuaryUsage.errorMsg ? fieldConsumptionJanuaryUsage.errorMsg : fieldConsumptionJanuaryUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionJanuaryUsage.value && handleError('fieldConsumptionJanuaryUsage')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">February</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionFebruaryUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionFebruaryUsage')}
                            error={fieldConsumptionFebruaryUsage.error}
                            helperText={fieldConsumptionFebruaryUsage.errorMsg ? fieldConsumptionFebruaryUsage.errorMsg : fieldConsumptionFebruaryUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionFebruaryUsage.value && handleError('fieldConsumptionFebruaryUsage')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">March</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionMarchUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionMarchUsage')}
                            error={fieldConsumptionMarchUsage.error}
                            helperText={fieldConsumptionMarchUsage.errorMsg ? fieldConsumptionMarchUsage.errorMsg : fieldConsumptionMarchUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionMarchUsage.value && handleError('fieldConsumptionMarchUsage')}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">April</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionAprilUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionAprilUsage')}
                            error={fieldConsumptionAprilUsage.error}
                            helperText={fieldConsumptionAprilUsage.errorMsg ? fieldConsumptionAprilUsage.errorMsg : fieldConsumptionAprilUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionAprilUsage.value && handleError('fieldConsumptionAprilUsage')}

                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">May</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionMayUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionMayUsage')}
                            error={fieldConsumptionMayUsage.error}
                            helperText={fieldConsumptionMayUsage.errorMsg ? fieldConsumptionMayUsage.errorMsg : fieldConsumptionMayUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionMayUsage.value && handleError('fieldConsumptionMayUsage')}

                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">June</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionJuneUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionJuneUsage')}
                            error={fieldConsumptionJuneUsage.error}
                            helperText={fieldConsumptionJuneUsage.errorMsg ? fieldConsumptionJuneUsage.errorMsg : fieldConsumptionJuneUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionJuneUsage.value && handleError('fieldConsumptionJuneUsage')}

                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">July</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionJulyUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionJulyUsage')}
                            error={fieldConsumptionJulyUsage.error}
                            helperText={fieldConsumptionJulyUsage.errorMsg ? fieldConsumptionJulyUsage.errorMsg : fieldConsumptionJulyUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionJulyUsage.value && handleError('fieldConsumptionJulyUsage')}

                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">August</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionAugustUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionAugustUsage')}
                            error={fieldConsumptionAugustUsage.error}
                            helperText={fieldConsumptionAugustUsage.errorMsg ? fieldConsumptionAugustUsage.errorMsg : fieldConsumptionAugustUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionAugustUsage.value && handleError('fieldConsumptionAugustUsage')}

                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">September</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionSeptemberUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionSeptemberUsage')}
                            error={fieldConsumptionSeptemberUsage.error}
                            helperText={fieldConsumptionSeptemberUsage.errorMsg ? fieldConsumptionSeptemberUsage.errorMsg : fieldConsumptionSeptemberUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionSeptemberUsage.value && handleError('fieldConsumptionSeptemberUsage')}

                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">October</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionOctoberUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionOctoberUsage')}
                            error={fieldConsumptionOctoberUsage.error}
                            helperText={fieldConsumptionOctoberUsage.errorMsg ? fieldConsumptionOctoberUsage.errorMsg : fieldConsumptionOctoberUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionOctoberUsage.value && handleError('fieldConsumptionOctoberUsage')}

                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">November</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionNovemberUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionNovemberUsage')}
                            error={fieldConsumptionNovemberUsage.error}
                            helperText={fieldConsumptionNovemberUsage.errorMsg ? fieldConsumptionNovemberUsage.errorMsg : fieldConsumptionNovemberUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionNovemberUsage.value && handleError('fieldConsumptionNovemberUsage')}

                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">December</Typography>
                          </div>
                          <TextField
                            label="Usage in kWh*"
                            variant="outlined"
                            fullWidth
                            value={fieldConsumptionDecemberUsage.value}
                            onChange={(e) => handleMonthlyUsageChange(e, 'fieldConsumptionDecemberUsage')}
                            error={fieldConsumptionDecemberUsage.error}
                            helperText={fieldConsumptionDecemberUsage.errorMsg ? fieldConsumptionDecemberUsage.errorMsg : fieldConsumptionDecemberUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionDecemberUsage.value && handleError('fieldConsumptionDecemberUsage')}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={4} xl={3}>
                        <div className="consumption-grid-box">
                          <div className="grid-header">
                            <em className="gird-icon">
                              <img src={calendar} alt="calendar" />
                            </em>
                            <Typography variant="h6">Annual</Typography>
                          </div>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Usage in kWh*"
                            value={fieldConsumptionAnnualUsage.value}
                            onChange={handleAnnualUsageChange}
                            error={fieldConsumptionAnnualUsage.error}
                            helperText={fieldConsumptionAnnualUsage.errorMsg ? fieldConsumptionAnnualUsage.errorMsg : fieldConsumptionAnnualUsage.error ? 'Required' : ''}
                            onBlur={() => !fieldConsumptionAnnualUsage.value && handleError('fieldConsumptionAnnualUsage')}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="button-combo-group">
                    <div className="combo-left">
                      <Button
                        disabled={
                          !fieldConsumptionAnnualUsage.value ||
                          fieldConsumptionAnnualUsage.error ||
                          !fieldConsumptionPricePerKWH.value ||
                          fieldConsumptionPricePerKWH.error ||
                          !fieldConsumptionPriceBaseFee.value ||
                          fieldConsumptionPriceBaseFee.error ||
                          !fieldConsumptionSelfProducedPower.value ||
                          fieldConsumptionSelfProducedPower.error ||
                          !fieldConsumptionAdditionalIncentive.value ||
                          fieldConsumptionAdditionalIncentive.error
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (fieldConsumptionAnnualUsage.value && fieldConsumptionPricePerKWH.value) {
                            setState((oldState) => ({ ...oldState, exampleNum: 0 }))
                            calculateSolarNumbers()
                            scrollToSolarNumber()
                          } else {
                            handleError(!fieldConsumptionAnnualUsage.value ? 'fieldConsumptionAnnualUsage' : 'fieldConsumptionPricePerKWH');
                          }
                        }}
                      >
                        Calculate
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setStateClear()}
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="combo-right">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setExample1()}
                      >
                        Example 1
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setExample2()}
                      >
                        Example 2
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setExample3()}
                      >
                        Example 3
                      </Button>
                    </div>
                  </div>
                  <ul className="calculate-list">
                    <li>Assuming 2.50 a watt solar cost.</li>
                    <li>Assuming 600kWh per panel.</li>
                    <li>
                      Assuming solar panels are facing south at optimal angle
                    </li>
                    <li>Assuming no shade</li>
                  </ul>
                </CardContent>
              </Card>
            </Container>
          </section>
          <div ref={solarNumberRef}>
            <SolarNumber solarCalculationData={solarCalculationData} />
          </div>
          <AddBattery />
          <AddCost />
          <ContactUs />

          {/* Finance Block */}
          <section className="finance-section">
            <Container maxWidth="xl">
              <Typography variant="h3" className="h3">
                Financing
              </Typography>
              <p className="finance-content">
                Local Solar installers have access to provide third party zero
                down financing that allows for monthly payments.
              </p>
              <Link to="/financing" className='w-100'>
                <Button variant="contained" color="primary">
                  read more
                </Button>
              </Link>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
});

export default Home;
