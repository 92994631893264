import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function AddBattery() {
  return (
    <>
      <section className="add-battery-section">
        <Container maxWidth="xl">
          <Typography variant="h4">Want to add a battery?</Typography>
          <div className="battery-box">
            <div className="battery-info">
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 7.5k
                </Typography>
                <span>5 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 10k
                </Typography>
                <span>10 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 12.5k
                </Typography>
                <span>15 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 15k
                </Typography>
                <span>20 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 17.5k
                </Typography>
                <span>25 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 20k
                </Typography>
                <span>30 kWh</span>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link to="/battery-calculate" className='w-100'>
              <Button variant="contained" color="primary">
                Battery calculator
              </Button>
            </Link>
          </div>
        </Container>
      </section>
    </>
  )
}
