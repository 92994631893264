import React from 'react'
import { Typography, Dialog, IconButton, } from "@mui/material";
import { checkGreen, modalClose } from '../../assets/images';

export default function SuccessDialog({ open, onClose, successValue }) {

  const successDialogOff = () => {
    onClose(successValue);
  };

  return (
    <>
      <Dialog className="c-dialog" onClose={successDialogOff} open={open}>
        <IconButton className="btn-close" onClick={successDialogOff}>
          <img src={modalClose} alt="close" />
        </IconButton>
        <em className="status-round">
          <img src={checkGreen} alt="check" />
        </em>
        <div className="status-block">
          <Typography variant="h5" className="text-success">
            Thank you!
          </Typography>
          <p className="success-message">Your Submission has been received!</p>
        </div>
      </Dialog>
    </>
  )
}
