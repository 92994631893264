export const EXAMPLE2 = [
    {
        name: 'Fridge',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: '',
        power: '',
        error: false,
        errorMsg: ""
    },
    {
        name: 'Freezer',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: '',
        power: '',
        error: false,
        errorMsg: ""
    },
    {
        name: 'Lighting',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: '',
        power: '',
        error: false,
        errorMsg: ""
    },
    {
        name: 'Heater electric element',
        power: 2,
        error: false,
        errorMsg: ""
    },
    {
        name: '',
        power: '',
        error: false,
        errorMsg: ""
    },
]

export const EXAMPLE1 = [
    {
        name: 'AC unit',
        power: 10,
        error: false,
        errorMsg: ""
    },
    {
        name: 'Lighting',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: '2nd AC unit',
        power: 5,
        error: false,
        errorMsg: ""
    }, {
        name: 'Internet',
        power: 0.5,
        error: false,
        errorMsg: ""
    },
    {
        name: 'Fridge',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: 'Washer',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: 'Freezer',
        power: 1,
        error: false,
        errorMsg: ""
    },
    {
        name: 'Dryer',
        power: 1,
        error: false,
        errorMsg: ""
    }, {
        name: 'TV/computers',
        power: 1,
        error: false,
        errorMsg: ""
    },

    {
        name: 'Appliances',
        power: 1,
        error: false,
        errorMsg: ""
    },
]