export const logo = require('./logo.png');
export const footerLogo = require('./footer-logo.png');
export const infoRound = require('./ic-info.svg').default;
export const infoWhite = require('./ic-info-white.svg').default;
export const dropdown = require('./ic-dropdown.svg').default;
export const calendar = require('./ic-calendar.svg').default;
export const cost = require('./cost-bg.jpg');
export const robotCheck = require('./robot-check.png');
export const check = require('./ic-check.svg').default;
export const solarGroup = require('./solar-panel-group.png');
export const solarSingle = require('./solar-module.png');
export const close = require('./ic-close.svg').default;
export const accordionOpenIc = require('./accordion-open.svg').default;
export const accordionClose = require('./accordion-close.svg').default;
export const modalClose = require('./modal-close.svg').default;
export const checkGreen = require('./check-green.svg').default;